import { getSalaries } from "@/utils/api";

export default {
  GET_SALARIES({ commit }) {
    return new Promise((resolve, reject) => {
      getSalaries()
        .then((resp) => {
          commit("SET_SALARIES", resp.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
