<template>
  <div class="chip">
    <div class="chip-text">{{ text }}</div>
    <div class="chip-close" @click="onDeleteChip()">
      <font-awesome-icon icon="trash" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["text"],

  emits: ["delete-chip"],

  methods: {
    onDeleteChip() {
      this.$emit("delete-chip", this.text);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/add_salary.scss";
</style>
