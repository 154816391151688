<template>
  <label :for="name">{{ label }}</label>
  <select @change="(resp) => onCreateChip(resp)">
    <option></option>

    <template
      v-for="suggestion in suggestions"
      :value="suggestion.name"
      :key="suggestion.id"
    >
      <option :value="suggestion.name">{{ suggestion.name }}</option>
    </template>
  </select>

  <p class="error">{{ message }}</p>
  <p class="help">{{ help }}</p>
  <div class="chip-packet">
    <template v-for="c in chips" :key="c">
      <Chip :text="c" v-on:delete-chip="onDeleteChip" />
    </template>
  </div>
</template>

<script>
import Chip from "@/components/general/Chip.vue";

export default {
  components: { Chip },

  data() {
    return {
      chips: this.preValue,
    };
  },

  emits: ["updateChips"],

  methods: {
    onCreateChip(e) {
      if (e.target.value && this.chips.includes(e.target.value) === false) {
        this.chips.push(e.target.value);
        this.$emit("updateChips", this.chips);
      }
    },
    onDeleteChip(e) {
      this.chips = this.chips.filter((value) => value != e);
    },
  },

  props: ["label", "suggestions", "name", "preValue", "message", "help"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/compare.scss";
</style>
