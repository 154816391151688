import { createStore } from "vuex";

// CURRENCIES
import currencyA from "./actions/currency";
import currencyG from "./getters/currency";
import currencyM from "./mutations/currency";
import currencyS from "./states/currency";

// SUGGESTIONS
import salaryA from "./actions/salary";
import salaryG from "./getters/salary";
import salaryM from "./mutations/salary";
import salaryS from "./states/salary";

// SUGGESTIONS
import suggestionsA from "./actions/suggestions";
import suggestionsG from "./getters/suggestions";
import suggestionsM from "./mutations/suggestions";
import suggestionsS from "./states/suggestions";

const actions = {
  ...currencyA,
  ...salaryA,
  ...suggestionsA,
  CLEAR_ALL_DATA({ commit }) {
    commit("CLEAR_ALL_DATA");
  },
};

const getters = {
  ...currencyG,
  ...salaryG,
  ...suggestionsG,
};

const mutations = {
  ...currencyM,
  ...salaryM,
  ...suggestionsM,
  CLEAR_ALL_DATA(state) {
    Object.assign(state, getDefaultState());
  },
};

const getDefaultState = () => {
  return {
    ...currencyS,
    ...salaryS,
    ...suggestionsS,
  };
};
const state = getDefaultState();

export default createStore({
  actions,
  getters,
  mutations,
  state,
  plugins: [],
});
