export default {
  suggestions: (state) => state.suggestions,
  suggestionsGenders: (state) => state.suggestions.genders,
  suggestionsRaces: (state) => state.suggestions.races,
  suggestionsCountries: (state) => state.suggestions.countries,
  suggestionsCities: (state) => state.suggestions.cities,
  suggestionsCurrencies: (state) => state.suggestions.currencies,
  suggestionsEducations: (state) => state.suggestions.education,
  suggestionsIndustries: (state) => state.suggestions.industries,
  suggestionsTitles: (state) => state.suggestions.titles,
};
