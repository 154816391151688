export const currenciesMaps = [
  {
    name: "pound",
    symbol: "GBP",
  },
  {
    name: "dollar",
    symbol: "USD",
  },
  {
    name: "rand",
    symbol: "ZAR",
  },
  {
    name: "euro",
    symbol: "EUR",
  },
  {
    name: "canadian dollar",
    symbol: "CAD",
  },
];
