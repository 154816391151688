<template>
  <datalist :id="`dataList${name}`">
    <option
      v-for="suggestion in suggestions"
      :key="suggestion.id"
      v-bind:value="suggestion.name"
    ></option>
  </datalist>

  <div class="group">
    <label :for="name">{{ label }}</label>

    <input
      :name="name"
      :placeholder="placeholder"
      :type="type"
      v-model="value"
      :list="`dataList${name}`"
      @blur="ForceLower"
      @change="ForceLower"
      @focus="ForceLower"
      @input="ForceLower"
    />
    <p class="error">{{ message }}</p>
    <p class="help">{{ help }}</p>
  </div>
</template>

<script>
export default {
  props: [
    "label",
    "suggestions",
    "name",
    "preValue",
    "placeholder",
    "type",
    "message",
    "help",
  ],

  data() {
    return { value: this.preValue };
  },

  emits: ["onChange"],

  methods: {
    ForceLower() {
      this.value = this.value.toLowerCase();
      this.$emit("onChange", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/add_salary.scss";
</style>
