<template>
  <main>
    <template v-if="isLoaded === false">
      <div
        :style="{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }"
      >
        <pulse-loader :loading="!isLoaded" :size="'50px'"></pulse-loader>
      </div>
    </template>
    <template v-else>
      <section class="add-container">
        <h1>Add Salary</h1>
        <div class="divider"></div>

        <!-- AGE -->
        <div class="group">
          <label for="age">Age *</label>
          <input
            name="age"
            placeholder="age"
            type="number"
            v-model="age.value"
            @blur="(resp) => onValueChanged(age, resp.target.value)"
            @change="(resp) => onValueChanged(age, resp.target.value)"
            @focus="(resp) => onValueChanged(age, resp.target.value)"
            @input="(resp) => onValueChanged(age, resp.target.value)"
          />
          <p class="error">{{ age.message }}</p>
          <p class="help">enter your current age</p>
        </div>
        <!-- AGE -->

        <!-- GENDER -->
        <AutoCompleteInput
          :label="'Gender *'"
          :suggestions="suggestionsGenders"
          :name="'genders'"
          :preValue="gender.value"
          :placeholder="'gender '"
          :type="'text'"
          :message="gender.message"
          :help="'enter your prefered gender'"
          v-on:onChange="(resp) => onValueChanged(gender, resp)"
        />
        <!-- GENDER -->

        <!-- RACE -->
        <AutoCompleteInput
          :label="'Race *'"
          :suggestions="suggestionsRaces"
          :name="'races'"
          :preValue="race.value"
          :placeholder="'race'"
          :type="'text'"
          :message="race.message"
          :help="'enter your race'"
          v-on:onChange="(resp) => onValueChanged(race, resp)"
        />
        <!-- RACE -->

        <!-- COUNTRY -->
        <AutoCompleteInput
          :label="'Country *'"
          :suggestions="suggestionsCountries"
          :name="'countries'"
          :preValue="country.value"
          :placeholder="'country'"
          :type="'text'"
          :message="country.message"
          :help="'enter the current country you reside in'"
          v-on:onChange="(resp) => onValueChanged(country, resp)"
        />
        <!-- COUNTRY -->

        <!-- CITY -->
        <AutoCompleteInput
          :label="'City *'"
          :suggestions="suggestionsCities"
          :name="'cities'"
          :preValue="city.value"
          :placeholder="'city'"
          :type="'text'"
          :message="city.message"
          :help="'enter the current city you reside in'"
          v-on:onChange="(resp) => onValueChanged(city, resp)"
        />
        <!-- CITY -->

        <!-- CURRENCY -->
        <AutoCompleteInput
          :label="'Currency *'"
          :suggestions="suggestionsCurrencies"
          :name="'currencies'"
          :preValue="currency.value"
          :placeholder="'currency'"
          :type="'text'"
          :message="currency.message"
          :help="'enter the currency you are paid in'"
          v-on:onChange="(resp) => onValueChanged(currency, resp)"
        />
        <!-- CURRENCY -->

        <!-- EDUCATION -->
        <AutoCompleteInput
          :label="'Education *'"
          :suggestions="suggestionsEducations"
          :name="'educations'"
          :preValue="education.value"
          :placeholder="'education'"
          :type="'text'"
          :message="education.message"
          :help="'select your level of education'"
          v-on:onChange="(resp) => onValueChanged(education, resp)"
        />
        <!-- EDUCATION -->

        <!-- INDUSTRY -->
        <AutoCompleteInput
          :label="'Industry *'"
          :suggestions="suggestionsIndustries"
          :name="'industries'"
          :preValue="industry.value"
          :placeholder="'industry'"
          :type="'text'"
          :message="industry.message"
          :help="'enter the industry you work in e.g. film, finances, media, etc'"
          v-on:onChange="(resp) => onValueChanged(industry, resp)"
        />
        <!-- INDUSTRY -->

        <!-- JOB TITLE -->
        <AutoCompleteInput
          :label="'Job Title *'"
          :suggestions="suggestionsTitles"
          :name="'titles'"
          :preValue="title.value"
          :placeholder="'title'"
          :type="'text'"
          :message="title.message"
          :help="'enter your job title e.g. doctor, builder, jounalist, etc'"
          v-on:onChange="(resp) => onValueChanged(title, resp)"
        />
        <!-- JOB TITLE -->

        <!-- WORK EXPERIENCE -->
        <!-- YEARS -->
        <div class="group">
          <label for="experience">Total years experience *</label>
          <input
            name="experience"
            placeholder="experience"
            type="number"
            v-model="experience.value"
            @blur="(resp) => onValueChanged(experience, resp.target.value)"
            @change="(resp) => onValueChanged(experience, resp.target.value)"
            @focus="(resp) => onValueChanged(experience, resp.target.value)"
            @input="(resp) => onValueChanged(experience, resp.target.value)"
          />
          <p class="error">{{ experience.message }}</p>
          <p class="help">enter your total years experience</p>
        </div>
        <!-- YEARS -->
        <!-- WORK EXPERIENCE -->

        <!-- SALARY -->
        <div class="group">
          <label for="salary">Salary *</label>
          <input
            name="salary"
            placeholder="salary"
            type="number"
            v-model="salary.value"
            @blur="(resp) => onValueChanged(salary, resp.target.value)"
            @change="(resp) => onValueChanged(salary, resp.target.value)"
            @focus="(resp) => onValueChanged(salary, resp.target.value)"
            @input="(resp) => onValueChanged(salary, resp.target.value)"
          />
          <p class="error">{{ salary.message }}</p>
          <p class="help">
            enter your total salary before any deductions are taken
          </p>
        </div>
        <!-- SALARY -->

        <div class="group">
          <button @click="recaptcha">
            <div class="text">Submit</div>
            <div class="icon">
              <font-awesome-icon class="nav-toggle" icon="paper-plane" />
            </div>
          </button>
        </div>
      </section>
    </template>
  </main>
</template>

<script>
// COMPONENTS
import AutoCompleteInput from "@/components/general/AutoCompleteInput.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { createToast } from "mosha-vue-toastify";
// STYLES
import "mosha-vue-toastify/dist/style.css";
// SCRIPTS
import { validate } from "@/utils/validator.js";
import { addSalary } from "@/utils/api";

export default {
  components: { AutoCompleteInput, PulseLoader },

  data() {
    return {
      isLoaded: true,

      inputKeys: [
        "age",
        "gender",
        "race",
        "country",
        "city",
        "currency",
        "education",
        "industry",
        "title",
        "experience",
        "salary",
      ],

      age: {
        value: "",
        valid: false,
        validationType: "age",
        message: "",
        required: true,
      },

      gender: {
        value: "",
        valid: false,
        validationType: "name",
        message: "",
        required: true,
      },

      race: {
        value: "",
        valid: false,
        validationType: "name",
        message: "",
        required: true,
      },

      country: {
        value: "",
        valid: false,
        validationType: "text",
        message: "",
        required: true,
      },

      city: {
        value: "",
        valid: false,
        validationType: "text",
        message: "",
        required: true,
      },

      currency: {
        value: "",
        valid: false,
        validationType: "text",
        message: "",
        required: true,
      },

      education: {
        value: "",
        valid: false,
        validationType: "text",
        message: "",
        required: true,
      },

      industry: {
        value: "",
        valid: false,
        validationType: "text",
        message: "",
        required: true,
      },

      title: {
        value: "",
        valid: false,
        validationType: "text",
        message: "",
        required: true,
      },

      experience: {
        value: "",
        valid: false,
        validationType: "number",
        message: "",
        required: true,
      },

      salary: {
        value: "",
        valid: false,
        validationType: "salary",
        message: "",
        required: true,
      },
    };
  },

  computed: {
    suggestionsGenders: function () {
      return this.$store.getters.suggestionsGenders;
    },

    suggestionsRaces: function () {
      return this.$store.getters.suggestionsRaces;
    },

    suggestionsCountries: function () {
      return this.$store.getters.suggestionsCountries;
    },

    suggestionsCities: function () {
      return this.$store.getters.suggestionsCities.filter((element) =>
        element.relation.includes(this.country.value)
      );
    },

    suggestionsCurrencies: function () {
      return this.$store.getters.suggestionsCurrencies;
    },

    suggestionsEducations: function () {
      return this.$store.getters.suggestionsEducations;
    },

    suggestionsIndustries: function () {
      return this.$store.getters.suggestionsIndustries;
    },

    suggestionsTitles: function () {
      return this.$store.getters.suggestionsTitles.filter((element) =>
        element.relation.includes(this.industry.value)
      );
    },
  },

  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded();
      if (await this.$recaptcha("login")) {
        this.onSubmitSalary();
      }
    },

    onValueChanged(attribute, value) {
      let v = validate(attribute);
      attribute.message = v.message;
      attribute.valid = v.valid;
      attribute.value = value;
    },

    async onSubmitSalary() {
      this.isLoaded = false;
      let valid = true;
      let postData = {};
      for (let key of this.inputKeys) {
        let v = validate(this[key]);
        this[key].message = v.message;
        this[key].valid = v.valid;
        postData[`${key}`] = this[key].value;
        if (this[key].valid === false) valid = false;
      }
      if (valid === true) {
        addSalary(postData).then(() => {
          console.log("salary finished publishing");
          this.ClearInputs();
          this.toast("Submitted Salary", "success");
        });
      } else {
        this.toast("Invalid form check information", "danger");
      }
      this.isLoaded = true;
    },

    ClearInputs() {
      for (let element of this.inputKeys) {
        this[element].message = "";
        this[element].valid = false;
        this[element].value = "";
      }
    },
  },
  setup() {
    const toast = (message, type) => {
      createToast(message, {
        hideProgressBar: "true",
        showIcon: "true",
        type,
        timeout: 2000,
      });
    };
    return { toast };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/add_salary.scss";
</style>
