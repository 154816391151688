<template>
  <div class="chart-container">
    <Highcharts ref="highchartsRef" :options="options"></Highcharts>
  </div>
</template>

<script>
// SCRIPTS
import _ from "lodash";
import { currenciesMaps } from "@/utils/constants.js";
import { currencyConvert } from "@/utils/helpers.js";

export default {
  props: ["chartData"],

  data() {
    return {};
  },

  computed: {
    options: function () {
      return {
        chart: {
          width: window.innerWidth / 1.5,
          height: window.innerHeight / 1.5,
        },
        title: {
          text: "Salary by experience",
        },
        subtitle: {
          text: "based on highest salary available",
        },
        tooltip: {
          valuePrefix: "R",
        },
        legend: {
          align: "center",
          horizontalAlign: "middle",
          borderWidth: 0,
        },
        xAxis: {
          title: {
            text: "years experience",
          },
        },
        yAxis: {
          title: {
            text: "salary amount",
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: "#808080",
            },
          ],
        },
        series: this.series,
      };
    },

    series: function () {
      const filteredData = this.chartData;

      const chartData = [];

      const titles = [...new Set(filteredData.map((element) => element.title))];

      let maxExperience = 0;
      let hasValue = 0;
      for (const title of titles) {
        const name = title;
        const sortedData = [];

        filteredData.forEach((filtered) => {
          for (let i = 0; i < 20; i++) {
            let salaryValue = 0;
            if (
              filtered.title === title &&
              parseInt(filtered.experience) === i
            ) {
              salaryValue = parseFloat(filtered.salary);

              if (filtered.currency !== "rand") {
                salaryValue = currencyConvert(
                  currenciesMaps.filter(
                    (cur) => cur.name === filtered.currency
                  )[0].symbol,
                  "zar",
                  salaryValue
                );
              }

              if (maxExperience < i) maxExperience = i;
            }
            sortedData.push({
              x: i,
              y: salaryValue,
            });
            if (salaryValue > hasValue) hasValue = salaryValue;
          }
        });

        if (hasValue !== 0) {
          const data = [];
          _(sortedData)
            .groupBy("x")
            .mapValues((a) => {
              let value = _.maxBy(a, "y").y;
              if (value === 0) value = null;
              data.push({ x: a[0].x, y: value });
            })
            .value();
          chartData.push({ name, data });
        }
      }

      return chartData.map((element) => {
        const { name, data } = element;
        return { name, data: data.slice(0, maxExperience + 2) };
      });
    },
  },
};
</script>

<style></style>
