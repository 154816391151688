<template>
  <main>
    <section class="title-container">
      <div class="title-text">
        <h1>WageOwl</h1>
        <p>
          anonymously share your salary, helping others in your field gauge how
          much they should be earning.
        </p>
      </div>
      <div class="title-image">
        <img src="@/assets/images/company_brand.png" alt="branding" />
      </div>
    </section>

    <About />

    <Contact v-on:notify="ShowNotify" />
  </main>
</template>

<script>
import About from "@/views/About.vue";
import Contact from "@/views/Contact.vue";

export default {
  name: "Home Page",
  components: {
    About,
    Contact,
  },
  methods: {
    ShowNotify(e) {
      this.$emit("notify", e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/home.scss";
</style>
