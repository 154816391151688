<template>
  <main>
    <section class="container">
      <h1>Compare Salary</h1>

      <template v-if="chartData.length > 0">
        <LineChart :chartData="chartData" />
      </template>

      <h2>Filters</h2>

      <!-- AGE -->
      <div class="group">
        <label for="age">Age</label>
        <div class="group-2">
          <input
            name="age"
            placeholder="start age"
            type="number"
            v-model="age.value"
          />
          <input
            name="age"
            placeholder="end age"
            type="number"
            v-model="age.value2"
          />
        </div>
        <p class="error">{{ age.message }}</p>
        <p class="help">define a range for age</p>
      </div>
      <!-- AGE -->

      <!-- GENDER -->
      <div class="group">
        <SelectionInput
          :label="'Gender'"
          :suggestions="suggestionsGenders"
          :name="'gender'"
          :preValue="gender.chips"
          :message="gender.message"
          :help="'select desired genders'"
          v-on:updateChips="(resp) => (gender.chips = resp)"
        />
      </div>
      <!-- GENDER -->

      <!-- RACE -->
      <div class="group">
        <SelectionInput
          :label="'Race'"
          :suggestions="suggestionsRaces"
          :name="'race'"
          :preValue="race.chips"
          :message="race.message"
          :help="'select desired races'"
          v-on:updateChips="(resp) => (race.chips = resp)"
        />
      </div>
      <!-- RACE -->

      <!-- COUNTRY -->
      <div class="group">
        <SelectionInput
          :label="'Country'"
          :suggestions="suggestionsCountries"
          :name="'country'"
          :preValue="country.chips"
          :message="country.message"
          :help="'select desired countries'"
          v-on:updateChips="(resp) => (country.chips = resp)"
        />
      </div>
      <!-- COUNTRY -->

      <!-- CITY -->
      <div class="group">
        <SelectionInput
          :label="'City'"
          :suggestions="suggestionsCities"
          :name="'city'"
          :preValue="city.chips"
          :message="city.message"
          :help="'select desired cities'"
          v-on:updateChips="(resp) => (city.chips = resp)"
        />
      </div>
      <!-- CITY -->

      <!-- CURRENCY -->
      <div class="group">
        <SelectionInput
          :label="'Currency'"
          :suggestions="suggestionsCurrencies"
          :name="'currency'"
          :preValue="currency.chips"
          :message="currency.message"
          :help="'select desired currencies'"
          v-on:updateChips="(resp) => (currency.chips = resp)"
        />
      </div>
      <!-- CURRENCY -->

      <!-- EDUCATION -->
      <div class="group">
        <SelectionInput
          :label="'Education'"
          :suggestions="suggestionsEducations"
          :name="'education'"
          :preValue="education.chips"
          :message="education.message"
          :help="'select desired education levels'"
          v-on:updateChips="(resp) => (education.chips = resp)"
        />
      </div>
      <!-- EDUCATION -->

      <!-- INDUSTRY -->
      <div class="group">
        <SelectionInput
          :label="'Industry'"
          :suggestions="suggestionsIndustries"
          :name="'industry'"
          :preValue="industry.chips"
          :message="industry.message"
          :help="'select desired industries'"
          v-on:updateChips="(resp) => (industry.chips = resp)"
        />
      </div>
      <!-- INDUSTRY -->

      <!-- TITLE -->
      <div class="group">
        <SelectionInput
          :label="'Title'"
          :suggestions="suggestionsTitles"
          :name="'title'"
          :preValue="title.chips"
          :message="title.message"
          :help="'select desired job titles'"
          v-on:updateChips="(resp) => (title.chips = resp)"
        />
      </div>
      <!-- TITLE -->

      <!-- WORK EXPERIENCE -->
      <div class="group">
        <label for="age">Experience</label>
        <div class="group-2">
          <input
            name="expStart"
            placeholder="start experience"
            type="number"
            v-model="experience.value"
          />
          <input
            name="expEnd"
            placeholder="end experience"
            type="number"
            v-model="experience.value2"
          />
        </div>
        <p class="error">{{ experience.message }}</p>
        <p class="help">define a range for experience</p>
      </div>
      <!-- WORK EXPERIENCE -->

      <div class="group">
        <button @click="onGenerateChart">
          <div class="text">Generate</div>
          <div class="icon">
            <font-awesome-icon icon="cogs" />
          </div>
        </button>
      </div>
    </section>
  </main>
</template>

<script>
import SelectionInput from "@/components/general/SelectionInput.vue";
import LineChart from "@/components/charts/LineChart.vue";
export default {
  components: { SelectionInput, LineChart },
  data() {
    return {
      chartData: [],
      age: {
        value: "",
        value2: "",
        valid: false,
        validationType: "age",
        message: "",
        required: true,
      },
      gender: {
        message: "",
        chips: [],
      },
      race: {
        message: "",
        chips: [],
      },
      country: {
        message: "",
        chips: [],
      },
      city: {
        message: "",
        chips: [],
      },
      currency: {
        message: "",
        chips: [],
      },
      education: {
        message: "",
        chips: [],
      },
      industry: {
        message: "",
        chips: [],
      },
      title: {
        message: "",
        required: true,
        chips: [],
      },
      experience: {
        value: "",
        value2: "",
        valid: false,
        validationType: "number",
        message: "",
        required: true,
      },
      salary: {
        value: "",
        valid: false,
        validationType: "number",
        message: "",
        required: true,
      },
    };
  },

  mounted() {
    this.onGenerateChart();
  },

  methods: {
    onGenerateChart() {
      let data = this.$store.getters.salaries;

      // FILTER BY AGE
      if (this.age.value)
        data = data.filter((element) => element.age >= this.age.value);
      if (this.age.value2)
        data = data.filter((element) => element.age <= this.age.value2);

      // FILTER BY GENDER
      if (this.gender.chips.length > 0)
        data = data.filter((element) =>
          this.gender.chips.includes(element.gender)
        );

      // FILTER BY RACE
      if (this.race.chips.length > 0)
        data = data.filter((element) => this.race.chips.includes(element.race));

      // FILTER BY COUNTRY
      if (this.country.chips.length > 0)
        data = data.filter((element) =>
          this.country.chips.includes(element.country)
        );

      // FILTER BY CITY
      if (this.city.chips.length > 0)
        data = data.filter((element) => this.city.chips.includes(element.city));

      // FILTER BY CURRENCY
      if (this.currency.chips.length > 0)
        data = data.filter((element) =>
          this.currency.chips.includes(element.currency)
        );

      // FILTER BY EDUCATION
      if (this.education.chips.length > 0)
        data = data.filter((element) =>
          this.education.chips.includes(element.education)
        );

      // FILTER BY INDUSTRY
      if (this.industry.chips.length > 0)
        data = data.filter((element) =>
          this.industry.chips.includes(element.industry)
        );

      // FILTER BY TITLE
      if (this.title.chips.length > 0)
        data = data.filter((element) =>
          this.title.chips.includes(element.title)
        );

      // FILTER BY EXPERIENCE
      if (this.experience.value)
        data = data.filter(
          (element) => element.experience >= this.experience.value
        );
      if (this.experience.value2)
        data = data.filter(
          (element) => element.experience <= this.experience.value2
        );

      this.chartData = data;
    },
  },

  props: ["suggestions"],

  computed: {
    suggestionsGenders: function () {
      return this.$store.getters.suggestionsGenders;
    },

    suggestionsRaces: function () {
      return this.$store.getters.suggestionsRaces;
    },

    suggestionsCountries: function () {
      return this.$store.getters.suggestionsCountries;
    },

    suggestionsCities: function () {
      return this.$store.getters.suggestionsCities.filter((element) =>
        element.relation.includes(this.country.value)
      );
    },

    suggestionsCurrencies: function () {
      return this.$store.getters.suggestionsCurrencies;
    },

    suggestionsEducations: function () {
      return this.$store.getters.suggestionsEducations;
    },

    suggestionsIndustries: function () {
      return this.$store.getters.suggestionsIndustries;
    },

    suggestionsTitles: function () {
      return this.$store.getters.suggestionsTitles;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/compare.scss";
</style>
