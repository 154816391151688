<template>
  <template v-if="isLoaded === false">
    <div
      :style="{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }"
    >
      <pulse-loader :loading="!isLoaded" :size="'50px'"></pulse-loader>
    </div>
  </template>
  <template v-else>
    <Navigation />
    <router-view />
    <Footer />
  </template>
</template>

<script>
// COMPONENTS
import Navigation from "@/components/navigation/Navigation.vue";
import Footer from "@/components/navigation/Footer.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "core",
  components: {
    Navigation,
    Footer,
    PulseLoader,
  },

  mounted() {
    const suggestionsPromise = this.$store.dispatch("GET_SUGGESTIONS");
    const salariesPromise = this.$store.dispatch("GET_SALARIES");
    const currencyRatesPromise = this.$store.dispatch("GET_CURRENCIES");
    Promise.all([
      suggestionsPromise,
      salariesPromise,
      currencyRatesPromise,
    ]).then(() => {
      this.isLoaded = true;
    });
  },

  data() {
    return { isLoaded: false };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/base.scss";
@import "@/assets/styles/typography.scss";
</style>
