import { getSuggestions } from "@/utils/api";

export default {
  GET_SUGGESTIONS({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.suggestions.length === 0) {
        getSuggestions()
          .then((response) => {
            commit("GET_SUGGESTIONS", response.data);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve();
      }
    });
  },
};
