import { getCurrencyRate } from "@/utils/api";

export default {
  GET_CURRENCIES({ commit }) {
    return new Promise((resolve, reject) => {
      getCurrencyRate()
        .then((resp) => {
          commit("SET_CURRENCIES", resp.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
