import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import AddSalary from "@/views/AddSalary.vue";
import CompareSalary from "@/views/CompareSalary.vue";
import Policy from "@/views/Policy.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/add-salary",
    name: "Add Salary",
    component: AddSalary,
  },
  {
    path: "/compare-salary",
    name: "Compare Salary",
    component: CompareSalary,
  },
  {
    path: "/policy",
    name: "Policy",
    component: Policy,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
