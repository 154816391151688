<template>
  <header>
    <div class="logo-container">
      <div class="left-side">
        <h4 class="nav-text">WageOwl</h4>
      </div>
      <div class="right-side">
        <a v-on:click="ToggleNavi()">
          <font-awesome-icon class="nav-toggle" icon="bars" />
        </a>
      </div>
    </div>
    <nav v-bind:class="{ hideNavi: hideNavi, showNavi: showNavi }">
      <div class="nav-links">
        <a
          class="nav-link"
          v-bind:class="[active == 'Home' ? 'active' : '']"
          v-on:click="onPageChange('/')"
          >home</a
        >
        <hr class="low-res" />
        <a
          class="nav-link"
          v-bind:class="[active == 'Add Salary' ? 'active' : '']"
          v-on:click="onPageChange('add-salary')"
          >add</a
        >
        <hr class="low-res" />
        <a
          class="nav-link"
          v-bind:class="[active == 'Compare Salary' ? 'active' : '']"
          v-on:click="onPageChange('compare-salary')"
          >compare</a
        >
        <hr class="low-res" />
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      hideNavi: true,
      showNavi: false,
    };
  },

  computed: {
    active: function () {
      const _active = this.$router.currentRoute.value.name;
      if (_active) return _active;
      return "";
    },
  },

  methods: {
    ToggleNavi() {
      this.hideNavi = !this.hideNavi;
      this.showNavi = !this.showNavi;
    },
    onPageChange(page) {
      this.$router.push(page);
      this.ToggleNavi();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/navibar.scss";
</style>
