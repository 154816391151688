import axios from "axios";
import jwt from "jsonwebtoken";

const functions_url = process.env.VUE_APP_FUNCTIONS_URL;

// GET
export const getSuggestions = () => {
  return axios.get(`${functions_url}/get-suggestions`, {
    headers: {
      Authorization: `Bearer ${jwt.sign({}, process.env.VUE_APP_API_TOKEN)}`,
    },
  });
};

export const getSalaries = () => {
  return axios.get(`${functions_url}/get-salaries`, {
    headers: {
      Authorization: `Bearer ${jwt.sign({}, process.env.VUE_APP_API_TOKEN)}`,
    },
  });
};

export const getCurrencyRate = () => {
  return axios.get(`https://api.frankfurter.app/latest`);
};

// POST
export const addSalary = (payload) => {
  return axios.post(
    `${functions_url}/add-salary`,
    {
      params: { ...payload },
    },
    {
      headers: {
        Authorization: `Bearer ${jwt.sign({}, process.env.VUE_APP_API_TOKEN)}`,
      },
    }
  );
};

export const sendContactMessage = (payload) => {
  return axios.post(
    `${functions_url}/send-contact-message`,
    {
      params: { ...payload },
    },
    {
      headers: {
        Authorization: `Bearer ${jwt.sign({}, process.env.VUE_APP_API_TOKEN)}`,
      },
    }
  );
};
