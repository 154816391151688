import store from "@/store";

export const currencyConvert = (
  fromCurrencySymbol,
  toCurrencySymbol,
  currencyValue
) => {
  fromCurrencySymbol;
  toCurrencySymbol;
  const baseValue =
    store.getters.currencies.rates[`${fromCurrencySymbol.toUpperCase()}`];
  const toValue =
    store.getters.currencies.rates[`${toCurrencySymbol.toUpperCase()}`];
  return parseInt((currencyValue / baseValue) * toValue);
};
