<template>
  <main>
    <section class="contact-container">
      <template v-if="isLoaded === false">
        <div
          :style="{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }"
        >
          <pulse-loader :loading="!isLoaded" :size="'50px'"></pulse-loader>
        </div>
      </template>
      <template v-else>
        <h1>Contact us</h1>
        <div class="divider"></div>
        <div class="group">
          <label for="name">name</label>
          <input name="name" type="text" v-model="name.value" />
          <p class="error">{{ name.message }}</p>
        </div>
        <div class="group">
          <label for="email">email</label>
          <input name="email" type="email" v-model="email.value" />
          <p class="error">{{ email.message }}</p>
        </div>
        <div class="group">
          <label for="message">message *</label>
          <textarea
            name="message"
            cols="30"
            rows="10"
            maxlength="1000"
            v-model="message.value"
          ></textarea>
          <p class="error">{{ message.message }}</p>
        </div>
        <div class="group">
          <button @click="recaptcha">
            <div class="text">Send</div>
            <div class="icon">
              <font-awesome-icon class="nav-toggle" icon="paper-plane" />
            </div>
          </button>
        </div>
      </template>
    </section>
  </main>
</template>

<script>
// SCRIPTS
import { validate } from "@/utils/validator.js";
import { sendContactMessage } from "@/utils/api";
// COMPONENTS
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { createToast } from "mosha-vue-toastify";
// STYLES
import "mosha-vue-toastify/dist/style.css";

export default {
  name: "Contact Page",

  components: { PulseLoader },

  data() {
    return {
      isLoaded: true,

      name: {
        value: "",
        valid: false,
        validationType: "name",
        message: "",
        required: false,
      },
      email: {
        value: "",
        valid: false,
        validationType: "email",
        message: "",
        required: false,
      },
      message: {
        value: "",
        valid: false,
        validationType: "message",
        message: "",
        required: true,
      },
    };
  },

  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded();
      if (await this.$recaptcha("login")) {
        this.onSubmitMessage();
      }
    },

    async onSubmitMessage() {
      this.isLoaded = false;
      let valid = true;
      let postData = {};
      for (let i of ["name", "email", "message"]) {
        let v = validate(this[i]);
        this[i].message = v.message;
        this[i].valid = v.valid;
        postData[`${i}`] = this[i].value;
        if (this[i].valid === false) valid = false;
      }
      if (valid === true) {
        sendContactMessage(JSON.stringify(postData)).then(() => {
          this.onClearInputs();
          this.toast("Message sent", "success");
        });
      } else {
        this.toast("Please fill in required", "danger");
      }
      this.isLoaded = true;
    },
    async onClearInputs() {
      for (let i of ["name", "email", "message"]) {
        this[i].message = "";
        this[i].value = "";
        this[i].valid = false;
      }
    },
  },

  setup() {
    const toast = (message, type) => {
      createToast(message, {
        hideProgressBar: "true",
        showIcon: "true",
        type,
        timeout: 2000,
      });
    };
    return { toast };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/contact.scss";
</style>
