<template>
  <footer>
    <div class="footer-container">
      <h2>© 2021 WageOwl</h2>
      <a class="privacy-button">
        <router-link to="/policy">privacy policy</router-link>
      </a>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/footer.scss";
</style>
