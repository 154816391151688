<template>
  <main>
    <section class="about-container">
      <div class="text-group">
        <font-awesome-icon class="icon" icon="users" />
        <h1>Why share?</h1>
        <p>
          By sharing your salary you help others in your profession find the
          salary range they belong in. This is useful when salary reviews come
          up, or when applying for a new job at a different company.
        </p>
      </div>
      <div class="text-group">
        <font-awesome-icon class="icon" icon="lock" />
        <h1>Is it Safe?</h1>
        <p>
          WageOwl doesn't require any signup or input of personal information,
          well except your salary. So all submitted information is anonymous.
        </p>
      </div>
      <div class="text-group">
        <font-awesome-icon class="icon" icon="chart-pie" />
        <h1>Comparing</h1>
        <p>
          WageOwl allows you to fine tune your search requirement from location
          to experience, so as long as the data exists you will be able to find
          what you need.
        </p>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "About Page",
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/about.scss";
</style>
