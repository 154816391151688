import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Highcharts from "highcharts";
import VueHighcharts from "vue-highcharts";

import { VueReCaptcha } from "vue-recaptcha-v3";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
  faPaperPlane,
  faBars,
  faTrash,
  faCogs,
  faUsers,
  faLock,
  faChartPie,
} from "@fortawesome/free-solid-svg-icons";

library.add(faPaperPlane, faBars, faTrash, faCogs, faUsers, faLock, faChartPie);

createApp(App)
  .use(store)
  .use(router)
  .use(VueHighcharts, { Highcharts })
  .use(VueReCaptcha, { siteKey: "6Lekk5gUAAAAAEr8NwjDMYDCVQMG5Y81UJQfJNjN" })
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
